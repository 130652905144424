<template>
  <a-tabs>
    <a-tab-pane key="integra" tab="积分管理">
      <iss-integral-pane />
    </a-tab-pane>
    <a-tab-pane key="gift" tab="礼品管理">
      <iss-gift-pane />
    </a-tab-pane>
    <a-tab-pane key="log" tab="兑换记录">
      <iss-log-pane />
    </a-tab-pane>
    <a-tab-pane key="addr" tab="兑换地址">
      <iss-exchange-addr />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { Tabs } from 'ant-design-vue';
import IssIntegralPane from './components/integral';
import IssGiftPane from './components/gift';
import IssLogPane from './components/log';
import IssExchangeAddr from './components/exchangeAddr';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    IssIntegralPane,
    IssGiftPane,
    IssLogPane,
    IssExchangeAddr,
  },
};
</script>
