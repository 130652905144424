<template>
  <div class="iss-main">
    <search-form :items="items" :form="search" @fnSearch="handleFnSearch" />
    <grid
      ref="gridRef"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :search="search"
      :btn-operation="btnOperation"
      :scroll="{ x: 1400, y: gridHeight }"
    >
      <template #image="{ text }">
        <a-tooltip title="点击预览">
          <div class="iss-image"><a-image :src="text" /></div>
        </a-tooltip>
      </template>
      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>
  </div>
  <a-modal
    v-model:visible="visible"
    :title="form.id ? '编辑' : '新增'"
    :maskClosable="false"
    centered
    :confirm-loading="confirmLoading"
    @ok="handleOk"
  >
    <a-form
      class="iss-girf-form"
      ref="formRef"
      :model="form"
      :rules="rules"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 15 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item name="image" label="图片">
        <iss-image-upload v-model:value="form.image" list-type="picture-card" />
      </a-form-item>
      <a-form-item name="name" label="名称">
        <a-input v-model:value="form.name" placeholder="请输入名称" />
      </a-form-item>
      <a-form-item name="area" label="所属国家">
        <a-select
          v-model:value="form.area"
          :options="areaList"
          placeholder="请选择所属国家"
        />
      </a-form-item>
      <a-form-item name="askClass" label="会员等级">
        <a-select
          v-model:value="form.askClass"
          :options="askClassList"
          placeholder="请选择会员等级"
        />
      </a-form-item>
      <a-form-item name="amounts" label="总数量">
        <a-input-number
          v-model:value="form.amounts"
          :min="0"
          placeholder="请输入总数量"
        />
      </a-form-item>
      <a-form-item name="single" label="会员可兑换数量">
        <a-input-number
          v-model:value="form.single"
          :min="0"
          placeholder="请输入会员可兑换数量"
        />
      </a-form-item>
      <a-form-item name="score" label="兑换积分">
        <a-input-number
          v-model:value="form.score"
          :min="0"
          placeholder="请输入兑换积分"
        />
      </a-form-item>
      <a-form-item name="exchWay" label="兑换方式">
        <a-select
          v-model:value="form.exchWay"
          :options="exchWayList"
          placeholder="请选择兑换方式"
        />
      </a-form-item>
      <a-form-item name="date" label="起止时间">
        <a-range-picker v-model:value="form.date" valueFormat="YYYY-MM-DD" />
      </a-form-item>
      <a-form-item name="isVirtual" label="虚拟商品">
        <a-switch
          v-model:checked="form.isVirtual"
          checkedChildren="是"
          unCheckedChildren="否"
        />
      </a-form-item>
      <a-form-item name="description" label="描述">
        <a-textarea v-model:value="form.description" placeholder="请输入描述" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {
  DatePicker,
  Form,
  Image,
  InputNumber,
  message,
  Select,
  Switch,
  Tooltip,
} from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import IssImageUpload from '@/components/imageUpload';
import { dateFormat } from '@/utils';
import dictionaryApi from '@/api/dictionary';
import integralApi from '@/api/integral';

export default {
  components: {
    AImage: Image,
    ATooltip: Tooltip,
    AForm: Form,
    AFormItem: Form.Item,
    AInputNumber: InputNumber,
    ASelect: Select,
    ASwitch: Switch,
    ARangePicker: DatePicker.RangePicker,
    SearchForm,
    Grid,
    Operation,
    IssImageUpload,
  },
  data() {
    const askClassList = [
      { value: '所有会员', label: '所有会员' },
      { value: '普通会员', label: '普通会员' },
      { value: '白银会员', label: '白银会员' },
      { value: '黄金会员', label: '黄金会员' },
      { value: '钻石会员', label: '钻石会员' },
    ];
    const required = { required: true, message: '不能为空' };

    return {
      items: [
        { key: 'name', label: '名称' },
        {
          key: 'area',
          label: '国家',
          type: 'select',
          url: `${dictionaryApi.itemListUrl}?code=AREA`,
          valueKey: 'itemNameEn',
          labelKey: 'itemNameCn',
        },
        {
          key: 'askClass',
          label: '会员等级',
          type: 'select',
          dataset: askClassList,
        },
      ],
      columns: [
        {
          dataIndex: 'image',
          title: '图片',
          width: 80,
          slots: { customRender: 'image' },
        },
        { dataIndex: 'name', title: '名称' },
        // { dataIndex: 'code', title: '编码', width: 160 },
        { dataIndex: 'area', title: '国家', width: 160 },
        { dataIndex: 'askClass', title: '会员等级', width: 100 },
        { dataIndex: 'description', title: '描述' },
        { dataIndex: 'score', title: '兑换积分', width: 100 },
        { dataIndex: 'amounts', title: '总数量', width: 100 },
        {
          dataIndex: 'startTime',
          title: '起始时间',
          width: 130,
          customRender: ({ text }) => dateFormat(text),
        },
        {
          dataIndex: 'endTime',
          title: '结束时间',
          width: 130,
          customRender: ({ text }) => dateFormat(text),
        },
        {
          dataIndex: 'id',
          title: '操作',
          fixed: 'right',
          width: 90,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        { type: 'add', permission: 'gift:update', fnClick: this.handleEdit },
      ],
      options: [
        {
          type: 'update',
          permission: 'gift:update',
          fnClick: this.handleEdit,
        },
        {
          type: 'delete',
          permission: 'gift:delete',
          fnClick: ({ id }) =>
            integralApi.deleteGift('gift:delete', { ids: [id] }).then(() => {
              message.success('操作成功');
              this.$refs.gridRef.refreshGrid();
            }),
        },
      ],
      url: integralApi.giftPageUrl,
      gridHeight: document.body.clientHeight - 434,
      search: {},
      visible: false,
      confirmLoading: false,
      askClassList,
      areaList: [],
      exchWayList: [
        { value: '2', label: '快递形式' },
        { value: '1', label: '领取兑换' },
      ],
      form: {},
      rules: {
        image: required,
        name: required,
        area: required,
        amounts: required,
        score: required,
        single: required,
        date: required,
        description: required,
      },
      userIsAdmin: false,
    };
  },
  created() {
    this.getAreaList();
    this.getUserIsAdmin();
  },
  methods: {
    getAreaList() {
      dictionaryApi
        .getItemList(this.$route.path, { code: 'AREA' })
        .then(data => {
          this.areaList = data.map(item => ({
            value: item.itemNameEn,
            label: item.itemNameCn,
          }));
        });
    },
    getUserIsAdmin() {
      integralApi.getUserIsAdmin(this.$route.path).then(data => {
        this.userIsAdmin = data;
      });
    },
    handleFnSearch(value) {
      this.search = value;
    },
    handleEdit(item) {
      this.visible = true;
      this.form = item.id
        ? { ...item, date: [item.startTime, item.endTime] }
        : {
            id: '',
            image: '',
            name: '',
            area: null,
            askClass: '所有会员',
            exchWay: '1',
            amounts: 0,
            score: 0,
            single: 0,
            description: '',
            date: '',
            startTime: '',
            endTime: '',
            isVirtual: false,
          };
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        let temp = { ...this.form };
        temp.startTime = temp.date[0];
        temp.endTime = temp.date[1];
        delete temp.date;
        this.confirmLoading = true;
        integralApi
          .addGift('gift:add', temp)
          .then(() => {
            message.success('操作成功');
            this.visible = false;
            this.$refs.gridRef.refreshGrid();
          })
          .finally(() => (this.confirmLoading = false));
      });
    },

    // 删除礼品信息
    async delGift(id) {
      this.loading = true;
      const res = await integralApi.delGift({ ids: [id] });
      this.loading = false;

      this.$message({
        message: res ? '删除成功！' : '删除失败！',
        type: res ? 'success' : 'error',
      });
      // 请求失败终止执行
      if (!res) return;
    },

    // 修改礼品信息
    async editGift(giftData) {
      this.centerDialogVisible = true;
      // this.addParams.isVirtualBoolean = !!+giftData.isVirtual;
      this.dialogTitle = '修改礼品信息';
      // 把英文地区 转为替换为  中文回显
      Object.assign(this.addParams, giftData, {
        area: this.areaChEntransformation(giftData.area, 'Ch'),
      });
    },
  },
};
</script>
<style lang="less" scoped>
.iss-girf-form {
  height: 500px;
  overflow-y: auto;
}
.iss-image {
  cursor: pointer;
}
</style>
