<template>
  <div class="iss-main">
    <search-form :items="items" :form="search" @fnSearch="handleFnSearch" />
    <grid
      class="mt-10"
      ref="gridRef"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :search="search"
      :scroll="{ x: 1550, y: gridHeight }"
    >
      <template #status="{ text }">
        <a-tag :color="statusTagList[text][1]">
          {{ statusTagList[text][0] }}
        </a-tag>
      </template>
      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>
  </div>
  <a-modal
    v-model:visible="visible"
    title="编辑"
    :maskClosable="false"
    centered
    :confirm-loading="confirmLoading"
    @ok="handleOk"
  >
    <a-form
      ref="formRef"
      :model="form"
      :rules="rules"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 15 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item name="status" label="处理状态">
        <a-select
          v-model:value="form.status"
          :options="statusList"
          placeholder="请选择处理状态"
        />
      </a-form-item>

      <a-form-item name="adminRemarks" label="备注信息">
        <a-textarea
          v-model:value="form.adminRemarks"
          placeholder="请输入备注信息"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { Form, message, Select, Tag } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import dictionaryApi from '@/api/dictionary';
import integralApi from '@/api/integral';

export default {
  components: {
    ATag: Tag,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    SearchForm,
    Grid,
    Operation,
  },
  data() {
    const typeList = {
      0: 'EDM链接',
      1: '线上兑换',
      2: '手工录入',
    };
    const statusList = [
      { value: '0', label: '申请中' },
      { value: '1', label: '处理中' },
      { value: '2', label: '已处理' },
      { value: '3', label: '已驳回' },
      { value: '4', label: '已失效' },
    ];
    return {
      items: [
        { key: 'ismartekId', label: '会员名称' },
        {
          key: 'area',
          label: '国家',
          type: 'select',
          url: `${dictionaryApi.itemListUrl}?code=AREA`,
          valueKey: 'itemNameEn',
          labelKey: 'itemNameCn',
        },
        {
          key: 'status',
          label: '处理状态',
          type: 'select',
          dataset: statusList,
        },
      ],
      columns: [
        { dataIndex: 'goodsName', title: '礼品名称' },
        { dataIndex: 'ismartekId', title: '会员名称', width: 180 },
        { dataIndex: 'area', title: '所属国家', width: 100 },
        { dataIndex: 'token', title: '邮箱' },
        {
          dataIndex: 'type',
          title: '申请方式',
          width: 100,
          customRender: ({ text }) => typeList[text],
        },
        {
          dataIndex: 'applyTime',
          title: '申请时间',
          width: 180,
        },
        {
          dataIndex: 'status',
          title: '处理状态',
          width: 100,
          slots: { customRender: 'status' },
        },
        { dataIndex: 'remarks', title: '会员备注' },
        { dataIndex: 'adminRemarks', title: '管理员备注' },
        {
          dataIndex: 'id',
          title: '操作',
          fixed: 'right',
          width: 50,
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'update',
          permission: 'log:update',
          show: ({ status }) => status < 2,
          fnClick: record => {
            this.visible = true;
            this.form = { ...record };
          },
        },
      ],
      url: integralApi.logPageUrl,
      gridHeight: document.body.clientHeight - 403,
      search: {},
      statusTagList: {
        0: ['申请中', 'purple'],
        1: ['处理中', 'processing'],
        2: ['已处理', 'success'],
        3: ['已驳回', 'error'],
        4: ['已失效', 'warning'],
      },
      statusList,
      visible: false,
      confirmLoading: false,
      form: {},
      rules: {
        adminRemarks: { required: true, message: '不能为空' },
      },
    };
  },
  methods: {
    handleFnSearch(value) {
      this.search = value;
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true;
        integralApi
          .updateLog('log:update', this.form)
          .then(() => {
            message.success('操作成功');
            this.visible = false;
            this.$refs.gridRef.refreshGrid();
          })
          .finally(() => (this.confirmLoading = false));
      });
    },
  },
};
</script>
